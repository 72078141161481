/**
 * In some contexts (e.g., documentation), we want to ensure that any link to a
 * different domain opens in a new window/tab.
 */
export default function openRemoteLinksInNewWindow(selector) {
  document
    .querySelector(selector)
    .addEventListener("click", checkEventAndOpenLink)
}

// Use named function to avoid duplication during event listener registry.
function checkEventAndOpenLink(event) {
  if (!event.target.matches("a[href]")) return

  if (
    event.target.host &&
    event.target.host !== location.host &&
    !event.defaultPrevented
  ) {
    event.preventDefault()
    window.open(event.target.href, "_blank")
  }
}
