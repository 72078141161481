import { Component } from "react"
import PropTypes from "prop-types"

import Lock from "./lock"
import ZoomController from "./zoom_controller"

class Overlay extends Component {
  lockElement() {
    // location_point maps are maps that don't include the radius mask.
    // That means we can just drag them around normally
    const touchDevice = !!("ontouchstart" in document.documentElement)
    const pointMap = this.props.mapDirection === "location_point"

    if (touchDevice && !pointMap) return <Lock />
  }

  render() {
    const circleClasses = `arrows ${this.props.mapDirection}`
    const overlayClasses = `overlay ${this.props.mapDirection}`

    return (
      <div className={overlayClasses}>
        <div className="zoom-controls">
          <ZoomController
            className="zoom-in"
            symbol="&#43;"
            onAction={this.props.onZoomIn}
          />
          <ZoomController
            className="zoom-out"
            symbol="&#8722;"
            onAction={this.props.onZoomOut}
          />
        </div>

        <div className="circle-container">
          {this.lockElement()}

          <div className={circleClasses}>
            <div className="inner-circle" />
          </div>
        </div>
      </div>
    )
  }
}

Overlay.propTypes = {
  symbol: PropTypes.func,
  className: PropTypes.func,
  mapDirection: PropTypes.string,
  onZoomIn: PropTypes.func,
  onZoomOut: PropTypes.func,
}

export default Overlay
