import { Component } from "react"
import PropTypes from "prop-types"
import clone from "lodash/clone"

class LocationData extends Component {
  transformedFields() {
    const fields = this.props.locationFields
    const baseFieldName = this.props.fieldName

    const keys = Object.keys(fields)
    return keys.reduce((fieldNames, key) => {
      const newKey = `${baseFieldName}[${key}]`
      const newFieldNames = clone(fieldNames)
      newFieldNames[newKey] = fields[key]
      return newFieldNames
    }, {})
  }

  inputs() {
    const fields = this.transformedFields()
    return Object.keys(fields).map(fieldName => {
      return (
        <li key={fieldName}>
          <input
            type="hidden"
            name={fieldName}
            value={fields[fieldName] || ""}
            required={this.props.required}
          />
        </li>
      )
    })
  }

  render() {
    return <ul>{this.inputs()}</ul>
  }
}

LocationData.propTypes = {
  locationFields: PropTypes.object,
  fieldName: PropTypes.string,
  required: PropTypes.bool,
}

export default LocationData
