import { Component } from "react"
import PropTypes from "prop-types"
import GrowingTextArea from "shared/growing_text_area"
import InlineErrors from "shared/components/inline_errors"

export default class LimitedTextInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value || "",
    }

    this.onTextUpdate = this.onTextUpdate.bind(this)
  }

  onTextUpdate(value) {
    this.setState({ value })
    this.props.onChange && this.props.onChange(value)
  }

  valueForInput() {
    const { externallyControlledValue } = this.props
    return externallyControlledValue !== null
      ? externallyControlledValue
      : this.state.value
  }

  limitText() {
    const length = this.valueForInput().length
    return `${length}/${this.props.limit}`
  }

  limitStyle() {
    const length = this.state.value.length

    if (length > this.props.limit) {
      return { color: "rgb(191, 19, 19)" }
    } else {
      return {}
    }
  }

  render() {
    const {
      errors,
      minLength,
      limit,
      hardLimit,
      multiline,
      counterPosition,
      minRows,
      name,
      required,
      placeholder,
    } = this.props

    const hardLimitIfApplicable = hardLimit ? { maxLength: limit } : {}
    const minLengthAttr = minLength || minLength == 0 ? { minLength } : {}

    return (
      <div className="limited-text-input">
        {counterPosition === "top" && (
          <div className="limit limit-top" style={this.limitStyle()}>
            {this.limitText()}
          </div>
        )}

        <InlineErrors errors={errors} />

        {multiline ? (
          <GrowingTextArea
            value={this.valueForInput()}
            onChange={this.onTextUpdate}
            minRows={minRows}
            required={required}
            name={name}
            {...hardLimitIfApplicable}
          />
        ) : (
          <input
            type="text"
            value={this.valueForInput()}
            onChange={e => this.onTextUpdate(e.target.value)}
            placeholder={placeholder}
            required={required}
            name={name}
            {...hardLimitIfApplicable}
            {...minLengthAttr}
          />
        )}

        {counterPosition === "bottom" && (
          <div className="limit limit-bottom" style={this.limitStyle()}>
            {this.limitText()}
          </div>
        )}
      </div>
    )
  }
}

LimitedTextInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  externallyControlledValue: PropTypes.string,
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  limit: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  hardLimit: PropTypes.bool,
  multiline: PropTypes.bool,
  counterPosition: PropTypes.oneOf(["top", "bottom"]),
  minRows: PropTypes.number,
  required: PropTypes.bool,
  errors: InlineErrors.propTypes.errors,
}

LimitedTextInput.defaultProps = {
  multiline: true,
  counterPosition: "bottom",
  externallyControlledValue: null,
}
