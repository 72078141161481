import PropTypes from "prop-types"

import WarningBox from "shared/components/boxes/warning_box"
import { windowFeatures } from "shared/reconnect_window"

export default function ReconnectService({
  fieldOwnerServiceName,
  loadingOptions,
  onConnectSuccess,
  reconnectUrl,
}) {
  let popup = null

  const onReconnectClick = () => {
    if (loadingOptions) return

    if (popup == null || popup.closed) {
      popup = window.open(
        reconnectUrl,
        `${fieldOwnerServiceName}_connection`,
        windowFeatures
      )
    } else {
      popup.focus()
    }

    window.connectionFinished = () => {
      window.appendFlash("Service connected.", "success")
      onConnectSuccess()
    }
    window.connectionFailed = () => {
      window.appendFlash(
        `Could not connect ${fieldOwnerServiceName} right now. Please try again later.`,
        "danger"
      )
    }
  }

  const action = (
    <div onClick={() => onReconnectClick()}>
      <span>{loadingOptions ? "Loading..." : "Reconnect"}</span>
    </div>
  )

  return (
    <WarningBox
      message="This service has expired authorization, please reconnect."
      action={action}
    />
  )
}

ReconnectService.propTypes = {
  fieldOwnerServiceName: PropTypes.string.isRequired,
  loadingOptions: PropTypes.bool.isRequired,
  onConnectSuccess: PropTypes.func.isRequired,
  reconnectUrl: PropTypes.string.isRequired,
}
