import { Component } from "react"
import PropTypes from "prop-types"
import range from "lodash/range"
import flatten from "lodash/flatten"
import padStart from "lodash/padStart"

import "./time_of_day_field.scss"

export default class TimeOfDayField extends Component {
  constructor(props) {
    super(props)

    const [hour, minute] = this.props.value.split(":")

    this.state = {
      hour: parseInt(hour),
      minute: parseInt(minute),
    }

    this.onHourUpdate = this.onHourUpdate.bind(this)
    this.onMinuteUpdate = this.onMinuteUpdate.bind(this)
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.onChange && this.props.onChange(this.selectedTime())
    }, 0)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.hour != prevState.hour ||
      this.state.minute != prevState.minute
    ) {
      this.props.onChange && this.props.onChange(this.selectedTime())
    }
  }

  onHourUpdate(e) {
    this.setState({ hour: e.target.value })
  }

  onMinuteUpdate(e) {
    this.setState({ minute: e.target.value })
  }

  hourOptions() {
    const morning = range(1, 12).map(hour => {
      return {
        value: hour,
        label: `${padStart(hour, 2, "0")} am`,
      }
    })

    const afternoon = range(1, 12).map(hour => {
      return {
        value: hour + 12,
        label: `${padStart(hour, 2, "0")} pm`,
      }
    })

    const times = flatten([
      { value: 0, label: "12 am" },
      morning,
      { value: 12, label: "12pm" },
      afternoon,
    ])

    return times.map(time => {
      return (
        <option key={time.value} value={time.value}>
          {time.label}
        </option>
      )
    })
  }

  minuteOptions() {
    return ["00", "15", "30", "45"].map(m => (
      <option key={m} value={m}>
        {m} minutes
      </option>
    ))
  }

  selectedTime() {
    const hour = this.state.hour
    const minute = this.state.minute
    return `${padStart(hour, 2, "0")}:${padStart(minute, 2, "0")}`
  }

  render() {
    return (
      <div>
        <select
          styleName="hour-options"
          value={this.state.hour}
          onChange={this.onHourUpdate}
          disabled={this.props.disabled}
        >
          {this.hourOptions()}
        </select>

        <select
          styleName="minute-options"
          value={this.state.minute}
          onChange={this.onMinuteUpdate}
          disabled={this.props.disabled}
        >
          {this.minuteOptions()}
        </select>

        <input
          type="hidden"
          name={this.props.fieldName}
          value={this.selectedTime()}
        />
      </div>
    )
  }
}

TimeOfDayField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

TimeOfDayField.defaultProps = {
  value: "00:00",
}
