import { knownErrors } from "./error_map"

const swallowCancellation = promise => {
  promise?.catch(reason => {
    if (!knownErrors.includes(reason)) throw reason
  })
}

/**
 * This will catch any promise rejection with a known reason (cancellation, user needs upgrade, etc).
 * It can be given a Promise, or a function which returns a promise. In the
 * latter case, another function is returned which, when called, calls the
 * original function and catches the promise rejections from it. If the promise
 * is rejected with not maped reason, that will be allowed to bubble up.
 *
 * @param {Promise|function} promiseOrFunction
 * @returns when given a function, another function is returned
 */
export const verifyIntendedPromiseRejection = promiseOrFunction => {
  if (typeof promiseOrFunction === "function") {
    return () => {
      swallowCancellation(promiseOrFunction())
    }
  }
  swallowCancellation(promiseOrFunction)
}
