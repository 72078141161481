import { useState, useEffect } from "react"
import PropTypes from "prop-types"

import IngredientSuggestions from "./ingredient_suggestions"
import { ingredientsMetadataShape } from "app/lib/shared_prop_types"

const IngredientSuggestionsByService = ({
  ingredientsMetadata,
  addSuggestion,
  closeSuggestions,
  isOnColor,
  platformComposer,
  shouldHideSuggestions,
  useNamespacedIngredients,
  handleLastSelectedSuggestion,
  lastSelectedSuggestion,
}) => {
  const [optionsGroup, selectGroup] = useState([])

  const groupSteps = () => {
    const { trigger, queries = [] } = ingredientsMetadata

    return [trigger].concat(queries).reduce((acc, cur) => {
      if (!(cur && cur.service)) return acc
      return {
        ...acc,
        [cur.service.name]: (acc[cur.service.name] || []).concat(cur),
      }
    }, {})
  }

  const stepsByService = groupSteps()

  const groupIngredients = () => {
    return Object.keys(stepsByService).map(service =>
      stepsByService[service]
        .map(s => s.ingredients)
        .reduce((acc, cur) => acc.concat(cur), [])
        // Sub query ingredients are only supported in filter code
        .filter(i => i.value_type !== "query")
        .map(i => {
          return {
            [i.normalized_name.split(".", 2).join(".")]: {
              ...i,
              service: stepsByService[service][0]["service"],
            },
          }
        })
    )
  }

  // Option name will only be action/trigger name.
  const groupOptions = () => {
    return Object.keys(stepsByService).map((service, i) => (
      <optgroup label={service} key={i}>
        {stepsByService[service]
          // Include friendlier step name in ingredient
          .map(step =>
            step.ingredients.map(ingredient => ({
              ...ingredient,
              stepName: step.name,
            }))
          )
          // flatten
          .reduce((acc, ingredients) => [...acc, ...ingredients], [])
          // array of grouped namespaces, ie "ServiceName.stepName"
          .map(i => ({
            ...i,
            groupedName: i.normalized_name.split(".", 2).join("."),
          }))
          // array of grouped unique namespaces
          .reduce(
            (acc, cur) =>
              acc.some(i => i.groupedName === cur.groupedName)
                ? acc
                : [...acc, cur],
            []
          )
          // build options
          .map((ingredient, idx) => (
            <option key={`ingredient-${idx}`} value={ingredient.groupedName}>
              {ingredient.stepName}
            </option>
          ))}
      </optgroup>
    ))
  }

  const options = groupOptions()

  const suggestIngredients = stepNormalizedName => {
    const ingredients = groupIngredients().flat()
    const selectedGroup = ingredients
      .map(f => {
        return Object.keys(f).find(o => o === stepNormalizedName)
      })
      .reduce((acc, cur) => {
        return cur ? [...acc, cur] : acc
      }, [])[0]

    selectGroup(
      ingredients
        .filter(i => i.hasOwnProperty(selectedGroup))
        .map(o => o[selectedGroup])
    )
  }

  const renderSelector = () => {
    return <select disabled={options.length < 1}>{options}</select>
  }

  useEffect(() => {
    // build ingredient options for the very first step
    if (lastSelectedSuggestion) {
      suggestIngredients(lastSelectedSuggestion)
    } else {
      const firstValue = Object.keys(groupIngredients().flat()[0])[0]
      handleLastSelectedSuggestion(firstValue)
      suggestIngredients(firstValue)
    }
  }, [])

  return (
    <>
      {optionsGroup.length > 0 && (
        <IngredientSuggestions
          service={optionsGroup[0].service}
          trigger={optionsGroup[0]}
          suggestions={optionsGroup.map(opt => opt.name)}
          onSelect={selected => addSuggestion(selected)}
          onClose={closeSuggestions}
          isOnColor={isOnColor}
          platformComposer={platformComposer}
          selector={renderSelector()}
          onChange={selected => suggestIngredients(selected)}
          lastSelectedSuggestion={lastSelectedSuggestion}
          handleLastSelectedSuggestion={handleLastSelectedSuggestion}
          shouldHideSuggestions={clickedOuside =>
            shouldHideSuggestions(clickedOuside)
          }
          useNamespacedIngredients={useNamespacedIngredients}
        />
      )}
    </>
  )
}

IngredientSuggestionsByService.propTypes = {
  ingredientsMetadata: ingredientsMetadataShape.isRequired,
  addSuggestion: PropTypes.func.isRequired,
  closeSuggestions: PropTypes.func.isRequired,
  isOnColor: PropTypes.bool.isRequired,
  platformComposer: PropTypes.bool,
  shouldHideSuggestions: PropTypes.func.isRequired,
  useNamespacedIngredients: PropTypes.bool,
  handleLastSelectedSuggestion: PropTypes.func.isRequired,
  lastSelectedSuggestion: PropTypes.string,
}

export default IngredientSuggestionsByService
