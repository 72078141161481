import { Component } from "react"
import PropTypes from "prop-types"

import Overlay from "./overlay"

class MapField extends Component {
  constructor() {
    super()
    this.onMapIdle = this.onMapIdle.bind(this)
  }

  componentDidMount() {
    const { lat, lng, zoom, maxZoom, minZoom } = this.props

    this.mapComponent = new window.google.maps.Map(this.mapWrapperRef, {
      center: { lat, lng },
      disableDefaultUI: true,
      scrollwheel: false,
      streetViewControl: false,
      zoom,
      maxZoom,
      minZoom,
    })

    this.justCreated = true
    this.forcedAddress = true
    this.setCenter({ lat, lng })
    this.mapComponent.addListener("idle", this.onMapIdle)
  }

  // We gain a lot of simplicity by having the map overlay be really an overlay
  // of the map. But we should take care to re-render the map only when necessary
  shouldComponentUpdate(nextProps) {
    return this.props.mapDirection !== nextProps.mapDirection
  }

  onMapIdle() {
    if (this.justCreated) {
      this.props.onMapReady()
      this.justCreated = false
    }

    if (!this.forcedAddress) this.updateCenter(false)

    this.forcedAddress = false
    this.props.onMapIdle(this.mapComponent)
  }

  setCenter(latLng) {
    this.centerFromAddress = latLng
    this.mapComponent.setCenter(latLng)
  }

  getZoom() {
    return this.mapComponent.getZoom()
  }

  zoomIn() {
    const zoom = this.mapComponent.getZoom()
    if (zoom == this.props.maxZoom) return

    this.mapComponent.setZoom(zoom + 1)
    this.resetCenter()
  }

  zoomOut() {
    const zoom = this.mapComponent.getZoom()
    if (zoom == this.props.minZoom) return

    this.mapComponent.setZoom(zoom - 1)
    this.resetCenter()
  }

  calculateCenter() {
    const containerCenter = getContainerCenter()
    return this.pixelsToLatLng(containerCenter)
  }

  pixelsToLatLng(pixels) {
    if (!this.overlay) {
      this.overlay = new window.google.maps.OverlayView()
      this.overlay.draw = function () {}
      this.overlay.setMap(this.mapComponent)
    }

    const proj = this.overlay.getProjection()
    if (!proj) return

    const pos = new window.google.maps.Point(pixels.x, pixels.y)
    return proj.fromContainerPixelToLatLng(pos)
  }

  bounds() {
    return this.mapComponent.getBounds()
  }

  radius() {
    const center = getContainerCenter()
    const topCenter = getTopCenter()

    const centerLatLng = this.pixelsToLatLng(center)
    const topCenterLatLng = this.pixelsToLatLng(topCenter)

    if (centerLatLng === undefined || topCenterLatLng === undefined) return NaN

    return window.google.maps.geometry.spherical.computeDistanceBetween(
      topCenterLatLng,
      centerLatLng
    )
  }

  forceUpdate(latLng) {
    this.forcedAddress = true
    this.setCenter(latLng)
  }

  updateCenter(automated) {
    const newCenter = this.calculateCenter()
    // Probably overkill
    // I think we can get away with newCenter instead of the promise
    this.props.onCenterChanged(newCenter, automated).then(result => {
      this.centerFromAddress = result.geometry.location
    })
  }

  resetCenter() {
    this.forceUpdate(this.centerFromAddress)
  }

  render() {
    return (
      <div id="map-container">
        <div className="map" ref={r => (this.mapWrapperRef = r)} />
        <Overlay
          textAddress={this.props.textAddress}
          onZoomIn={this.props.onZoomIn}
          onZoomOut={this.props.onZoomOut}
          mapDirection={this.props.mapDirection}
        />
      </div>
    )
  }
}

MapField.defaultProps = {
  zoom: 16,
  minZoom: 2,
  maxZoom: 21,
}

MapField.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,
  onMapReady: PropTypes.func,
  onMapIdle: PropTypes.func,
  onCenterChanged: PropTypes.func,
  mapDirection: PropTypes.string,
  onZoomIn: PropTypes.func,
  onZoomOut: PropTypes.func,
  textAddress: PropTypes.string,
}

function getContainerCenter() {
  let container = document.querySelector(".inner-circle")
  const top = container.offsetTop
  const left = container.offsetLeft
  const width = container.offsetWidth
  const height = container.offsetHeight

  const x = width / 2 + left
  const y = height / 2 + top

  return { x, y }
}

function getTopCenter() {
  const containerCenter = getContainerCenter()
  return { x: containerCenter.x, y: 0 }
}

export default MapField
