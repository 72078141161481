// Taken from ifttt_front_end/app/models/ingredient.rb VALUE_TYPE_DATA
export default {
  string: {
    label: "String",
  },
  date: {
    label: "Date (ISO8601)",
  },
  datetime: {
    label: "Date with time (ISO8601)",
  },
  image_url: {
    label: "Image URL",
  },
  file_url: {
    label: "File URL",
  },
  web_url: {
    label: "Web URL",
  },
  query: {
    label: "Query",
  },
}
