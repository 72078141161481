import { Component, useContext } from "react"
import PropTypes from "prop-types"
import GrowingTextArea from "shared/growing_text_area"
import IngredientSuggestionsByService from "./ingredient_suggestions_by_service"
import IngredientSuggestions from "./ingredient_suggestions"
import { ingredientsMetadataShape } from "app/lib/shared_prop_types"
import flatten from "lodash/flatten"
import flatMap from "lodash/flatMap"
import FieldContext from "shared/contexts/field_context"
import parse from "html-react-parser"

class IngredientTextField extends Component {
  constructor(props) {
    super(props)

    this.suggestions = this.suggestions.bind(this)
    this.addSuggestion = this.addSuggestion.bind(this)
    this.closeSuggestions = this.closeSuggestions.bind(this)
    this.updateText = this.updateText.bind(this)

    this.handleFocusTextarea = this.handleFocusTextarea.bind(this)
    this.handleBlurTextarea = this.handleBlurTextarea.bind(this)
    this.handleClickIngredientsButton = this.handleClickIngredientsButton.bind(
      this
    )

    this.transformMirrorContent = this.transformMirrorContent.bind(this)
    this.shouldHideSuggestions = this.shouldHideSuggestions.bind(this)

    this.state = {
      text: this.props.value || "",
      showPreview: true,
      shouldHideSuggestions: true,
      lastSelectedSuggestion: "",
    }
  }

  componentDidMount() {
    this.props.onChange && this.props.onChange(this.state.text, true)
  }

  componentDidUpdate(prevProps) {
    const oldSuggestion = prevProps.fieldSuggestions && prevProps.fieldSuggestions[prevProps.fieldName]
    const suggestion = this.props.fieldSuggestions && this.props.fieldSuggestions[this.props.fieldName]

    if (suggestion && suggestion !== oldSuggestion) {
      this.setState({ text: suggestion })
    }
  }

  handleLastSelectedSuggestion(lastSelectedSuggestion) {
    this.setState({ lastSelectedSuggestion })
  }

  handleFocusTextarea() {
    this.setState({ showPreview: false })
  }

  handleBlurTextarea() {
    this.setState({
      showPreview: true,
      showSuggestions: !this.state.shouldHideSuggestions,
    })
  }

  handleClickIngredientsButton = e => {
    e.preventDefault()
    this.inputRef.focus()
    this.setState({ showSuggestions: true })
  }

  hasSuggestions() {
    try {
      const {
        trigger: { ingredients: triggerIngredients = [] },
        queries = [],
      } = this.props.ingredientsMetadata
      return (
        (triggerIngredients && triggerIngredients.length > 0) ||
        queries.some(q => (q.ingredients || []).length)
      )
    } catch {
      return false
    }
  }

  shouldHideSuggestions(clickedOuside) {
    this.setState({
      showSuggestions: !clickedOuside,
      shouldHideSuggestions: clickedOuside,
    })
  }

  suggestions() {
    // destructure props early to optimize rendering!
    const {
      platformComposer,
      ingredientsMetadata,
      isOnColor,
      hideIngredientNamespaces,
    } = this.props

    const { trigger, queries } = ingredientsMetadata
    const { lastSelectedSuggestion } = this.state

    if (platformComposer && queries && queries.length) {
      return (
        <IngredientSuggestionsByService
          ingredientsMetadata={ingredientsMetadata}
          addSuggestion={this.addSuggestion}
          closeSuggestions={this.closeSuggestions}
          isOnColor={isOnColor}
          platformComposer={platformComposer}
          shouldHideSuggestions={this.shouldHideSuggestions}
          hideIngredientNamespaces={hideIngredientNamespaces}
          lastSelectedSuggestion={lastSelectedSuggestion}
          handleLastSelectedSuggestion={selected =>
            this.handleLastSelectedSuggestion(selected)
          }
        />
      )
    } else {
      const triggerOptions = ((trigger && trigger.ingredients) || []).map(
        ingredient => ingredient.name
      )

      if (triggerOptions.length > 0) {
        return (
          <IngredientSuggestions
            service={trigger.service}
            trigger={trigger}
            suggestions={triggerOptions}
            onSelect={this.addSuggestion}
            onClose={this.closeSuggestions}
            isOnColor={isOnColor}
            shouldHideSuggestions={this.shouldHideSuggestions}
          />
        )
      }
    }
  }

  addSuggestion(ingredientName) {
    const currentText = this.state.text
    const cursorPosition = this.inputRef.getCursorPosition()

    const start = currentText.substring(0, cursorPosition)
    const rest = currentText.substring(cursorPosition, currentText.length)

    let newText = `{{${ingredientName}}}`

    // If the string was non-empty and ended with something other than
    // whitespace, add a space before the ingredient.
    if (start.match(/\S$/)) {
      newText = " " + newText
    }

    const cursorWithinIngredient = start.match(/{{(\w|\.)+}?$/)
    const textWithAddition = cursorWithinIngredient
      ? `${currentText}${newText}`
      : `${start}${newText}${rest}`
    this.updateText(textWithAddition)
    this.setState({ showSuggestions: false })

    window.setTimeout(() => {
      const newCursorPosition = cursorWithinIngredient
        ? textWithAddition.length
        : cursorPosition + newText.length
      this.inputRef.setCursorPosition(newCursorPosition)
    })
  }

  closeSuggestions() {
    this.setState({ showSuggestions: false })
  }

  transformMirrorContent(html) {
    const { trigger = {}, queries = [] } = this.props.ingredientsMetadata

    const validTriggerNames =
      trigger?.ingredients &&
      trigger.ingredients.map(ingredient => {
        if (this.props.platformComposer) {
          return [
            ingredient.name,
            ingredient.normalized_name.replace(/\./g, "\\."),
          ]
        } else {
          return [ingredient.name]
        }
      })

    const validQueryNames = flatMap(
      queries,
      query => query.ingredients
    ).map(ingredient => ingredient.normalized_name.replace(/\./g, "\\."))

    const validNames = [...flatten(validTriggerNames), ...validQueryNames]

    // If we're in a context where ingredients are not relevant (e.g.,
    // configuring a native applet as a user), don't highlight any ingredients.
    if (!validNames.length) {
      return html
    }

    const stripNamespaceRegex = /^(.*\\\.)?([^.]+)$/
    const transformedHtml = validNames.reduce((res, name) => {
      const simplifiedName = name.replace(stripNamespaceRegex, "$2")

      return res.replace(
        new RegExp(`{{${name}}}`, "g"),
        `<span class='ingredient-pill'>${simplifiedName}</span>`
      )
    }, html)

    const invalidRegex = /{{((\w|\.)+)}}/g
    return transformedHtml.replace(
      invalidRegex,
      "<span class='ingredient-pill-error'>$1</span>"
    )
  }

  updateText(text) {
    this.setState({ text })

    if (this.props.onChange) {
      this.props.onChange(text, false)
    }
  }

  render() {
    const classNames = [
      "ingredients-text-field",
      `ingredients-text-field-${this.props.type}`,
    ]

    if (this.props.isOnColor) {
      classNames.push("ingredients-text-field-on-color")
    }

    const noteP = this.props.note && (
      <p className="note">{parse(this.props.note )}</p>
    )

    const showButton = !this.props.disabled && this.hasSuggestions()

    return (
      <div className={classNames.join(" ")}>
        <GrowingTextArea
          ref={r => (this.inputRef = r)}
          value={this.state.text}
          onChange={this.updateText}
          onFocus={this.handleFocusTextarea}
          onBlur={this.handleBlurTextarea}
          name={this.props.fieldName}
          showMirror={this.state.showPreview}
          transformMirrorContent={this.transformMirrorContent}
          disabled={this.props.disabled}
        />

        {showButton && (
          <div className="tube">
            {noteP}
            <button
              className="ingredients-button"
              onMouseDown={e => e.preventDefault() /* avoid stealing focus */}
              onClick={this.handleClickIngredientsButton}
              title="Add ingredient"
            >
              Add ingredient
            </button>
          </div>
        )}

        {!showButton && noteP}

        {showButton && this.state.showSuggestions && this.suggestions()}
      </div>
    )
  }
}

IngredientTextField.propTypes = {
  fieldSuggestions: PropTypes.object,
  name: PropTypes.string,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  ingredientsMetadata: ingredientsMetadataShape.isRequired,
  type: PropTypes.oneOf(["text", "textarea"]),
  onChange: PropTypes.func,
  isOnColor: PropTypes.bool,
  note: PropTypes.string,
  disabled: PropTypes.bool,
  platformComposer: PropTypes.bool.isRequired,
  hideIngredientNamespaces: PropTypes.bool,
}

IngredientTextField.defaultProps = {
  type: "text",
  value: "",
  isOnColor: false,
  platformComposer: false,
}

const IngredientTextFieldWrapper = props => {
  const fieldSuggestions = useContext(FieldContext)
  return <IngredientTextField {...props} fieldSuggestions={fieldSuggestions} />
}

export default IngredientTextFieldWrapper
