import { Component } from "react"

class Lock extends Component {
  constructor() {
    super()

    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.onTouchMove = this.onTouchMove.bind(this)
  }

  onTouchMove() {
    this.moved = true
  }

  onTouchEnd() {
    if (this.moved) this.moved = false
    else this.unlock()
  }

  unlock() {
    this.lockRef.style.display = "none"
  }

  render() {
    return (
      <div
        ref={r => (this.lockRef = r)}
        className="lock"
        onTouchEnd={this.onTouchEnd}
        onTouchMove={this.onTouchMove}
      >
        tap to unlock
      </div>
    )
  }
}

export default Lock
