import { Component } from "react"
import PropTypes from "prop-types"

import "./check_boxes_field.scss"

class CheckBoxesField extends Component {
  state = {
    options: this.props.options || [],
    loading: true,
    selectedValues: this.props.initialValues || [],
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.options === null && this.props.options) {
      this.onOptionsLoaded(this.props.options)
    }

    if (this.state.selectedValues != prevState.selectedValues) {
      this.props.onChange && this.props.onChange(this.state.selectedValues)
    }
  }

  onOptionsLoaded(options) {
    this.setState({
      options,
      loading: false,
    })

    if (this.props.onChange) {
      this.props.onChange(this.state.selectedValues, true)
    }
  }

  onChange(e) {
    let currentValues = this.state.selectedValues
    const newValue = e.target.value

    if (currentValues.includes(newValue))
      currentValues = currentValues.filter(v => v !== newValue)
    else currentValues = currentValues.concat(newValue)

    this.setState({
      selectedValues: currentValues,
    })
  }

  checkbox(option, disabled = false) {
    const { isOnColor } = this.props
    const checked = this.state.selectedValues.includes(option.value)
    const optionName = `${this.props.fieldName}[]`

    return (
      <label className="checkbox">
        <input
          type="checkbox"
          name={optionName}
          onChange={e => this.onChange(e)}
          checked={checked}
          value={option.value}
          disabled={disabled}
        />
        <span styleName={isOnColor ? "visible-checkbox--on-color" : ""} />
        {option.label}
      </label>
    )
  }

  render() {
    if (this.state.loading) {
      return <span>Loading options…</span>
    }

    return (
      <ul>
        {this.state.options.map(option => (
          <li key={option.value} styleName="option">
            {this.checkbox(option, this.props.disabled)}
          </li>
        ))}
      </ul>
    )
  }
}

CheckBoxesField.propTypes = {
  disabled: PropTypes.bool,
  initialValues: PropTypes.array,
  name: PropTypes.string,
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  isOnColor: PropTypes.bool,
}

CheckBoxesField.defaultProps = {
  isOnColor: false,
}

export default CheckBoxesField
