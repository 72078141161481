import find from "lodash/find"
import groupBy from "lodash/groupBy"
import omit from "lodash/omit"
import { Component } from "react"
import PropTypes from "prop-types"

import "./double_select_field.scss"

class DoubleSelectField extends Component {
  state = {
    options: this.props.options || [
      {
        label: "…",
        value: null,
        group: "Loading options…",
      },
    ],
    loading: true,
  }

  constructor(props) {
    super(props)

    this.onParentSelectChange = this.onParentSelectChange.bind(this)
    this.onChildSelectChange = this.onChildSelectChange.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.options === null && this.props.options) ||
      prevProps.options !== this.props.options
    ) {
      this.onOptionsUpdated(this.props.options)
    }

    if (this.state.selectedValue != prevState.selectedValue) {
      const automated = prevState.loading !== this.state.loading
      this.props.onChange &&
        this.props.onChange(this.state.selectedValue, automated)
    }
  }

  onOptionsUpdated(options) {
    const selectedValue = this.props.selectedValue
    const topLevel = find(options, { value: selectedValue })
    const topLevelSelection = topLevel ? topLevel.group : undefined

    if (options.length > 0)
      options.unshift({
        label: "…",
        value: null,
        group: "Please select",
      })

    this.setState({
      options,
      selectedValue: this.props.selectedValue,
      topLevelSelection,
      loading: false,
    })
  }

  onParentSelectChange(e) {
    let selection = e.target.value
    let options = this.groupedOptions()[selection] || []

    this.setState({
      topLevelSelection: selection,
      selectedValue: options[0] && options[0].value,
    })
  }

  onChildSelectChange(e) {
    this.setState({
      selectedValue: e.target.value,
    })
  }

  groupedOptions() {
    // The backend returns an option with a null group, which causes some
    // weird side effects. We're removing that null key to simplify things.
    //
    let groupedOptions = groupBy(this.state.options, op => op.group)
    return omit(groupedOptions, [null])
  }

  topLevelOptions() {
    return Object.keys(this.groupedOptions())
  }

  topLevelOptionElements() {
    let options = this.topLevelOptions() || []

    return options.map(group => {
      const disabled =
        this.state.selectedValue &&
        this.props.required &&
        group === "Please select"

      return (
        <option disabled={disabled} key={group} value={group}>
          {group}
        </option>
      )
    })
  }

  secondaryOptionElements() {
    const selection = this.state.topLevelSelection
    let options = this.groupedOptions()[selection] || []

    return options.map(option => {
      const value = option.value
      return (
        <option key={value} value={value}>
          {option.label}
        </option>
      )
    })
  }

  secondarySelect() {
    const els = this.secondaryOptionElements() || []

    return (
      <select
        disabled={els.length === 0 || this.state.loading || this.props.disabled}
        name={this.props.fieldName}
        value={this.state.selectedValue}
        onChange={this.onChildSelectChange}
        required={this.props.required}
      >
        {els}
      </select>
    )
  }

  render() {
    return (
      <div className="fieldset" styleName="container">
        <select
          disabled={this.props.disabled}
          onChange={this.onParentSelectChange}
          value={this.state.topLevelSelection}
          required={this.props.required}
        >
          {this.topLevelOptionElements()}
        </select>

        {this.secondarySelect()}
      </div>
    )
  }
}

DoubleSelectField.propTypes = {
  disabled: PropTypes.bool,
  selectedValue: PropTypes.string,
  fieldName: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
}

export default DoubleSelectField
