export const windowFeatures =
  "menubar=no,location=yes,resizable=yes,scrollbars=yes,status=yes,left=100,top=100,width=500,height=710"

export const buildConnectionPath = (module_names, liveChannelId, returnTo) => {
  if (liveChannelId === null) {
    return `https://ifttt.com/bulk_channel_activation/new?c[]=${module_names}&return_to=${returnTo}`
  } else {
    return `https://ifttt.com/bulk_channel_activation/new?c[]=${module_names}&live_channel_id=${liveChannelId}&return_to=${returnTo}`
  }
}
