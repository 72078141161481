import { Component } from "react"
import PropTypes from "prop-types"
import forEach from "lodash/forEach"
import groupBy from "lodash/groupBy"

class SelectField extends Component {
  state = {
    options: this.props.options || [
      {
        label: "Loading…",
        group: null,
      },
    ],
    loading: true,
    selectedValue: this.props.selectedValue || "",
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options != this.props.options && this.props.options) {
      this.onOptionsUpdated(this.props.options)
    }
  }

  onOptionsUpdated(options) {
    this.setState({ loading: false })

    let selectedValue = this.state.selectedValue

    if (
      !selectedValue ||
      !options.some(option => option.value === selectedValue)
    ) {
      selectedValue = this.props.required ? options[0].value : null
    }

    this.setState({ options, selectedValue })

    if (this.props.onChange) {
      this.props.onChange(selectedValue, true)
    }
  }

  onChange(e) {
    this.setState({
      selectedValue: e.target.value,
    })

    if (this.props.onChange) {
      this.props.onChange(e.target.value)
    }
  }

  options() {
    let allOptions = []
    const disAllowedValues = ["null", "undefined"]
    const grouped = groupBy(this.state.options, o => o.group)
    forEach(grouped, (options, groupName) => {
      if (groupName && disAllowedValues.indexOf(groupName) === -1) {
        allOptions.push(
          <option disabled key={`${groupName}`} value={groupName}>
            {groupName}
          </option>
        )
      }

      options.forEach((option, index) => {
        if (
          disAllowedValues.indexOf(option.label) === -1 &&
          disAllowedValues.indexOf(option.value) === -1
        ) {
          allOptions.push(
            <option
              value={option.value}
              key={`${groupName}-${index}-${option.value}`}
            >
              {option.label}
            </option>
          )
        }
      })
    })

    !this.state.loading &&
      !this.props.required &&
      allOptions.unshift(
        <option value="" key="please-select">
          Please select
        </option>
      )

    return allOptions
  }

  render() {
    const { fieldName } = this.props

    return (
      <select
        disabled={this.props.disabled}
        name={fieldName}
        onChange={e => this.onChange(e)}
        value={this.state.selectedValue || ""}
      >
        {this.options()}
      </select>
    )
  }
}

SelectField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  selectedValue: PropTypes.string,
  fieldName: PropTypes.string,
  owner: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
}

export default SelectField
