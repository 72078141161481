import { Component } from "react"
import PropTypes from "prop-types"
import isEmpty from "lodash/isEmpty"
import debounce from "lodash/debounce"

import GrowingTextArea from "shared/growing_text_area"

class SearchBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      textAddress: props.textAddress,
      displaySearchIcon: false,
      focused: false,
    }

    this.onChange = this.onChange.bind(this)
    this.onClear = this.onClear.bind(this)
    this.submitEnteredAddress = this.submitEnteredAddress.bind(this)
    this.setEnteredAddress = this.setEnteredAddress.bind(this)
    this.debouncedSetAddress = debounce(this.setEnteredAddress, 1000)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.focused) {
      this.setState({ textAddress: nextProps.textAddress })
    }
  }

  // GrowingTextArea API sends the plain text not the event
  onChange = text => {
    this.setState(
      {
        textAddress: text,
      },
      () => this.debouncedSetAddress(text)
    )
  }

  onClear = () => {
    this.inputRef.textareaRef.focus()

    this.setState({
      textAddress: "",
      displaySearchIcon: true,
    })
  }

  setEnteredAddress(text) {
    this.props.onAddressChanged(text, false)

    !isEmpty(text) && this.setState({ displaySearchIcon: false })

    const touchDevice = !!("ontouchstart" in document.documentElement)
    touchDevice && this.inputRef.textareaRef.blur()
  }

  // Public: sends what is currently typed in the search bar, no events attached
  submitEnteredAddress() {
    this.setEnteredAddress(this.state.textAddress)
  }

  render() {
    return (
      <GrowingTextArea
        ref={r => (this.inputRef = r)}
        multiline={false}
        value={this.state.textAddress}
        onChange={this.onChange}
        onFocus={() => this.setState({ focused: true })}
        onBlur={e => {
          e.preventDefault()
          this.setState({ focused: false })
          this.submitEnteredAddress()
        }}
        onKeyDown={e => e.keyCode === 13 && this.submitEnteredAddress()}
        actionIcon={this.state.displaySearchIcon ? "search" : "clearText"}
        actionIconOnClick={
          this.state.displaySearchIcon
            ? this.submitEnteredAddress
            : this.onClear
        }
      />
    )
  }
}

SearchBar.propTypes = {
  textAddress: PropTypes.string,
  onAddressChanged: PropTypes.func,
}

export default SearchBar
