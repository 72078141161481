import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import classNames from "classnames"

import { LoadLiveChannels } from "shared/components/stored_fields/service_account_select_field/api"
import { buildConnectionPath, windowFeatures } from "shared/reconnect_window"
import groupBy from "lodash/groupBy"

import proPlusBadge from "images/plan-badges/pro_plus.svg"
import newFeatureBadge from "images/plan-badges/new-badge.svg"

import { verifyIntendedPromiseRejection } from "app/components/diy_composer/utils/modal_promise"

export default function ServiceAccountSelectField({
  field,
  fieldOwnerServiceId,
  fieldOwnerServiceName,
  onChange,
  updateServiceToForceRetry,
  userAllowMultipleLiveChannels,
  proFeatureGate,
}) {
  let popup = null
  const liveChannels = field.options
  const findAccount = id => liveChannels?.find(lc => lc.id === id)
  const [selectedAccount, updateSelectedAccount] = useState(
    findAccount(field.value)
  )
  const accountLabel = account => (account.offline ? "reconnect" : "add")
  const [accountActionLabel, updateAccountActionLabel] = useState(
    accountLabel(selectedAccount)
  )

  useEffect(() => {
    onChange(selectedAccount.id, true)
  }, [])

  const onAccountChange = evt => {
    const liveChannel = findAccount(evt.target.value)

    dispatchAccountChange(liveChannel)
  }

  const dispatchAccountChange = liveChannel => {
    updateSelectedAccount(liveChannel)
    updateAccountActionLabel(accountLabel(liveChannel))
    onChange(liveChannel.id, true)
  }

  const connectionPath = buildConnectionPath(
    fieldOwnerServiceId,
    accountActionLabel === "reconnect" ? selectedAccount.id : "",
    field.connectionFinishedUrl
  )

  const reloadServiceAccountsList = () => {
    LoadLiveChannels(fieldOwnerServiceId)
      .then(response => {
        if (!response.data) {
          window.appendFlash(
            "It fails to reload your accounts. Please, reload page and try again.",
            "danger"
          )
        } else {
          const reloadedLiveChannels = response.data.channel.live_channels
          const duplicatedConnection = Object.values(
            groupBy(
              reloadedLiveChannels.filter(lc => !lc.offline),
              "user_name_field"
            )
          ).find(lcs => lcs.length > 1)

          field.updateOptionsList(reloadedLiveChannels)

          if (accountActionLabel === "add") {
            dispatchAccountChange(
              reloadedLiveChannels[reloadedLiveChannels.length - 1]
            )
          } else {
            updateAccountActionLabel("add") // reset button label
            updateServiceToForceRetry?.(true)
          }

          if (duplicatedConnection) {
            window.appendFlash(
              `You added a duplicate account. Log into a new account on ${fieldOwnerServiceName} and try again.`,
              "success"
            )
          } else {
            window.appendFlash("Service connected successfully", "success")
          }
        }
      })
      .catch(() => {
        window.appendFlash(
          "It fails to reload your accounts. Please, reload page and try again.",
          "danger"
        )
      })
  }

  const onAddAccountClick = e => {
    e.preventDefault()

    if (field.showNewBadge) {
      field.hideNewBadge()
    }

    if (
      accountActionLabel !== "reconnect" &&
      userAllowMultipleLiveChannels === false
    ) {
      verifyIntendedPromiseRejection(proFeatureGate("multi_service_account"))
    } else {
      if (popup == null || popup.closed) {
        popup = window.open(
          connectionPath,
          `${fieldOwnerServiceId}_connection`,
          windowFeatures
        )
      } else {
        popup.focus()
      }

      window.connectionFinished = reloadServiceAccountsList
      window.connectionFailed = () =>
        window.appendFlash(
          `Could not connect ${fieldOwnerServiceName} right now. Please try again later.`,
          "danger"
        )
    }
  }

  const label =
    accountActionLabel === "reconnect" ? "Reconnect account" : "Add new account"

  const iconForButton = () => {
    if (field.showNewBadge) {
      return <span>{parse(newFeatureBadge)}</span>
    } else if (
      accountActionLabel !== "reconnect" &&
      userAllowMultipleLiveChannels === false
    ) {
      return (
        <span className="badge pro_plus">{parse(proPlusBadge)}</span>
      )
    }
  }

  return (
    <>
      <select value={selectedAccount.id} onChange={onAccountChange}>
        {liveChannels.map(live_channel => {
          return (
            <option key={live_channel.id} value={live_channel.id}>
              {live_channel.user_name_field}{" "}
              {live_channel.offline && `(Reconnect)`}
            </option>
          )
        })}
      </select>
      <div
        className={classNames("ingredients-text-field", {
          "ingredients-text-field-on-color": field.isOnColor,
        })}
      >
        <div className="tube">
          <button
            className={classNames("ingredients-button", {
              "ingredients-button-with-icon": !userAllowMultipleLiveChannels,
              "ingredients-button-with-icon new-badge-icon": field.showNewBadge,
            })}
            onClick={onAddAccountClick}
          >
            {iconForButton()}
            {label}
          </button>
        </div>
      </div>
    </>
  )
}

ServiceAccountSelectField.propTypes = {
  field: PropTypes.shape({
    connectionFinishedUrl: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        offline: PropTypes.bool.isRequired,
        user_name_field: PropTypes.string.isRequired,
      })
    ).isRequired,
    updateOptionsList: PropTypes.func.isRequired,
    isOnColor: PropTypes.bool,
    value: PropTypes.string,
    showNewBadge: PropTypes.bool,
    hideNewBadge: PropTypes.func,
  }),
  fieldOwnerServiceId: PropTypes.string.isRequired,
  fieldOwnerServiceName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  updateServiceToForceRetry: PropTypes.func,
  userAllowMultipleLiveChannels: PropTypes.bool,
  proFeatureGate: PropTypes.func,
}
