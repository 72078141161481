import PropTypes from "prop-types"
import IssueIcon from "shared/components/icons/issue_icon"

export default function WarningBox(props = {}) {
  return (
    <div className="warning-box">
      <div className="warning-box-icon">
        <IssueIcon />
      </div>
      <div className="warning-box-message">{props.message}</div>
      <div className="warning-box-action">{props.action}</div>
    </div>
  )
}

WarningBox.propTypes = {
  message: PropTypes.string,
  action: PropTypes.object,
}
