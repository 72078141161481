import { Component } from "react"
import PropTypes from "prop-types"

class ZoomController extends Component {
  constructor() {
    super()
    this.onAction = this.onAction.bind(this)
  }

  onAction(e) {
    e.preventDefault()
    this.props.onAction(e)
  }

  render() {
    return (
      <div className={`${this.props.className} controller`}>
        <button onClick={this.onAction}>{this.props.symbol}</button>
      </div>
    )
  }
}

ZoomController.propTypes = {
  onAction: PropTypes.func,
  className: PropTypes.string,
  symbol: PropTypes.string,
}

export default ZoomController
