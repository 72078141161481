import { Component } from "react"
import PropTypes from "prop-types"
import padStart from "lodash/padStart"
import last from "lodash/last"

import "./minutes_past_hour_field.scss"

export default class MinutesPastHour extends Component {
  constructor(props) {
    super(props)

    const minute = last(this.props.value.split(":"))
    this.state = { minute }
    this.onMinuteUpdate = this.onMinuteUpdate.bind(this)
  }

  componentDidMount() {
    if (this.props.onChange) {
      this.props.onChange(this.selectedTime())
    }
  }

  onMinuteUpdate(e) {
    this.setState({ minute: e.target.value })

    if (this.props.onChange) {
      this.props.onChange(this.selectedTime(e.target.value))
    }
  }

  minuteOptions() {
    return ["00", "15", "30", "45"].map(m => (
      <option key={m} value={m}>
        {m}
      </option>
    ))
  }

  selectedTime(value) {
    const minute = value || this.state.minute
    return `00:${padStart(minute, 2, "0")}`
  }

  render() {
    return (
      <div>
        <select
          styleName="minute-options"
          value={this.state.minute}
          onChange={this.onMinuteUpdate}
          disabled={this.props.disabled}
        >
          {this.minuteOptions()}
        </select>
        <input
          type="hidden"
          name={this.props.fieldName}
          value={this.selectedTime()}
        />
      </div>
    )
  }
}

MinutesPastHour.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

MinutesPastHour.defaultProps = {
  value: "00:00",
}
