import { Component } from "react"
import PropTypes from "prop-types"
import { get } from "shared/lib/api"
import parse from "html-react-parser"

import CheckBoxesField from "shared/components/stored_fields/check_boxes_field/index"
import LocationField from "shared/components/stored_fields/location_field/index"
import SelectField from "shared/components/stored_fields/select_field/index"
import DoubleSelectField from "shared/components/stored_fields/double_select_field/index"
import IngredientTextField from "shared/components/stored_fields/ingredient_text_field/index"
import TimeOfDayField from "shared/components/stored_fields/time_of_day_field"
import MinutesPastHourField from "shared/components/stored_fields/minutes_past_hour_field"
import DateAndTimeField from "shared/components/stored_fields/date_and_time_field"
import InlineErrors from "shared/components/inline_errors"
import RetryOptions from "shared/components/stored_fields/retry_options"
import ServiceAccountSelectField from "shared/components/stored_fields/service_account_select_field"
import ReconnectService from "app/components/stored_field/reconnect_service"

const SINGLE_SELECTABLE_FIELD_TYPES = [
  "collection_select",
  "double_collection_select",
]

const MULTIPLE_SELECTABLE_FIELD_TYPES = ["checkbox_multi"]

const ASYNC_FIELD_TYPES = [
  ...SINGLE_SELECTABLE_FIELD_TYPES,
  ...MULTIPLE_SELECTABLE_FIELD_TYPES,
]

export default class StoredField extends Component {
  state = {
    options: null,
    showAsyncRetry: false,
    reconnectUrl: null,
    loading: false,
    reloadingAfterSwitchAccounts: false,
  }

  componentDidMount() {
    this._isMounted = true
    if (ASYNC_FIELD_TYPES.includes(this.props.field.field_subtype)) {
      this.fetchOptions()
    } else {
      this.componentReady()
    }
  }

  // Reload dynamic dropdowns in case user switch between service accounts.
  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps) {
    if (
      this.props.updateFormEnabled &&
      ASYNC_FIELD_TYPES.includes(this.props.field.field_subtype) &&
      prevProps.liveChannelId != this.props.liveChannelId
    ) {
      this.props.updateFormEnabled(false)
      this.setState({
        reloadingAfterSwitchAccounts: true,
        options: [
          {
            label: "Loading…",
            group: null,
          },
        ],
      })
      this.fetchOptions(() => {
        if (
          SINGLE_SELECTABLE_FIELD_TYPES.includes(this.props.field.field_subtype)
        ) {
          this.props.onChange?.(this.state.options[0].value)
        } else if (
          MULTIPLE_SELECTABLE_FIELD_TYPES.includes(
            this.props.field.field_subtype
          )
        ) {
          this.props.onChange?.([])
        }
        this.props.updateFormEnabled(true)
      })
    }

    if (
      ASYNC_FIELD_TYPES.includes(this.props.field.field_subtype) &&
      prevProps.shouldForceRetryOptions === false &&
      this.props.shouldForceRetryOptions === true
    ) {
      this.fetchOptions()
      this.props.updateServiceToForceRetry(false)
    }

    if (!!prevProps.disabled && !this.props.disabled) {
      this.props.onReady?.(`${this.props.field.owner}.${this.props.field.name}`)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentReady() {
    this.props.onReady?.(`${this.props.field.owner}.${this.props.field.name}`)
  }

  fetchOptions(callback) {
    if (this.props.disableFetch) return

    this.setState({ loading: true })

    let optionsUrl = this.props.field.resolve_options
    if (typeof optionsUrl === "undefined") return

    if (optionsUrl && window.App.storedFieldPath) {
      optionsUrl = window.App.storedFieldPath(
        optionsUrl,
        this.props.liveChannelId
      )
    }

    get(optionsUrl)
      .then(response => {
        this.onOptionsFetched(response)
      })
      .catch((response, status, error) => {
        this.onOptionsFetchFailed(error)
      })
      .finally(() => {
        callback?.()
      })
  }

  optionsUnavailable() {
    return [
      {
        label: "No options available",
        group: null,
      },
    ]
  }

  onOptionsFetched(response) {
    if (!this._isMounted) return

    // Handle error
    if (response["error"]) {
      let { reconnectUrl } = this.state

      if (response["error"].indexOf("OAuth") > -1) {
        reconnectUrl = response["reconnect_url"]
      }

      this.setState({
        options: this.optionsUnavailable(),
        showAsyncRetry: true,
        reconnectUrl,
        loading: false,
      })

      return
    }

    // Handle empty response
    let options = response[this.props.field.name]
    if (typeof options === "undefined" || options.length === 0) {
      this.setState({
        options: this.optionsUnavailable(),
        showAsyncRetry: true,
        loading: false,
      })
      return
    }

    if (!this.state.reloadingAfterSwitchAccounts) {
      this.componentReady()
    }

    this.setState({
      options,
      showAsyncRetry: false,
      loading: false,
      reconnectUrl: null,
      reloadingAfterSwitchAccounts: false,
    })
  }

  onOptionsFetchFailed() {
    this._isMounted &&
      this.setState({
        options: this.optionsUnavailable(),
        showAsyncRetry: true,
        loading: false,
      })
  }

  render() {
    // Avoid show this warning box when stored field has `service_account_select` field.
    const serviceAllowMultipleAccounts =
      this.props.fieldOwnerAllowMultipleLiveChannels

    if (this.state.reconnectUrl && !serviceAllowMultipleAccounts) {
      return (
        <ReconnectService
          reconnectUrl={this.state.reconnectUrl}
          loadingOptions={this.state.loading}
          fieldOwnerServiceName={this.props.fieldOwnerServiceName}
          onConnectSuccess={() => this.fetchOptions()}
        />
      )
    }

    const {
      field,
      fieldName,
      value,
      ingredientsMetadata,
      platformComposer,
      onChange,
      isOnColor,
      disableFetch,
      fieldOwnerServiceName,
      fieldOwnerServiceId,
      errors,
      updateServiceToForceRetry,
    } = this.props

    const { showAsyncRetry } = this.state

    const fieldSubType = field.field_subtype
    let currentValue = field.is_hidden
      ? field.default_value // hidden field doesn't have live field
      : value || (field.value != null ? field.value : field.default_value) // Use default value when applet is not enabled (null) or user value (even blank)

    const disabled =
      this.props.disabled || (disableFetch && field.resolve_options)

    let helperText = field.helper_text?.trim()

    // You should show queries as platform composer when owner is accessing unpublished static applet on consumer view
    const actASplatformComposer =
      this.props.useIngredientsForAppletOwners || platformComposer

    // So we can submit a form with params
    const customName = fieldName || `fields[${field.name}]`
    const baseParams = {
      name: field.name,
      initialValues: currentValue,
      value: currentValue,
      selectedValue: currentValue,
      ingredientsMetadata,
      platformComposer: actASplatformComposer,
      fieldName: customName,
      required: field.required,
      options: this.state.options,
      hideIngredientNamespaces: this.props.hideIngredientNamespaces,
      disabled,
      onChange: (...args) => onChange?.(...args),
    }

    let input
    switch (fieldSubType) {
      case "text_field":
      case "text_area":
        input = (
          <IngredientTextField
            type={fieldSubType === "text_field" ? "text" : "textarea"}
            isOnColor={isOnColor}
            note={helperText}
            {...baseParams}
          />
        )
        // The <IngredientTextField> renders the helper text for us
        helperText = null
        break
      case "time_select":
        input = <TimeOfDayField {...baseParams} />
        break
      case "minute_select":
        input = <MinutesPastHourField {...baseParams} />
        break
      case "datetime_no_year_select":
        input = <DateAndTimeField {...baseParams} />
        break
      case "checkbox_multi":
        input = <CheckBoxesField {...baseParams} isOnColor={isOnColor} />
        break
      case "location_point":
      case "location_radius":
      case "location_enter":
      case "location_exit":
      case "location_enter_and_exit":
        if (currentValue) {
          currentValue.lat = currentValue.latitude
          currentValue.lng = currentValue.longitude
          baseParams.location = currentValue
        }

        input = <LocationField mapDirection={fieldSubType} {...baseParams} />
        break
      case "collection_select":
        input = <SelectField {...baseParams} />
        break
      case "service_account_select":
        input = (
          <ServiceAccountSelectField
            field={field}
            onChange={value => onChange?.(value)}
            updateServiceToForceRetry={updateServiceToForceRetry}
            fieldOwnerServiceId={fieldOwnerServiceId}
            fieldOwnerServiceName={fieldOwnerServiceName}
            userAllowMultipleLiveChannels={
              this.props.userAllowMultipleLiveChannels
            }
            proFeatureGate={this.props.proFeatureGate}
          />
        )
        break
      case "double_collection_select":
        input = <DoubleSelectField {...baseParams} />
        break
      default:
        throw new Error(`Unknown field type: ${fieldSubType}`)
    }

    return (
      <div>
        <InlineErrors errors={errors} isOnColor={isOnColor} />
        {input}
        {helperText && (
          <p className="note">{parse(helperText)}</p>
        )}
        {showAsyncRetry && !disabled && (
          <section className="async-retry">
            <RetryOptions
              field={this}
              fieldName={fieldName}
              serviceName={fieldOwnerServiceName}
              onRetry={() => this.fetchOptions()}
            />
          </section>
        )}
      </div>
    )
  }
}

StoredField.propTypes = {
  disabled: PropTypes.bool,
  disableFetch: PropTypes.bool,
  errors: InlineErrors.propTypes.errors,
  field: PropTypes.object,
  fieldName: PropTypes.string,
  fieldOwnerAllowMultipleLiveChannels: PropTypes.bool.isRequired,
  fieldOwnerServiceId: PropTypes.string,
  fieldOwnerServiceName: PropTypes.string.isRequired,
  hideIngredientNamespaces: PropTypes.bool,
  ingredientsMetadata: PropTypes.object,
  isOnColor: PropTypes.bool,
  liveChannelId: PropTypes.string,
  onChange: PropTypes.func,
  onInvalidResolution: PropTypes.func,
  onReady: PropTypes.func,
  platformComposer: PropTypes.bool,
  shouldForceRetryOptions: PropTypes.bool,
  updateFormEnabled: PropTypes.func,
  updateServiceToForceRetry: PropTypes.func,
  useIngredientsForAppletOwners: PropTypes.bool,
  value: PropTypes.any,
  userAllowMultipleLiveChannels: PropTypes.bool.isRequired,
  proFeatureGate: PropTypes.func,
}

StoredField.defaultProps = {
  disabled: false,
  fieldOwnerAllowMultipleLiveChannels: false,
  hideIngredientNamespaces: false,
  isOnColor: false,
  platformComposer: false,
  shouldForceRetryOptions: false,
  updateServiceToForceRetry: () => {},
  useIngredientsForAppletOwners: false,
}
