class Geocoder {
  statusIsIgnorable(status) {
    return [
      window.google.maps.GeocoderStatus.INVALID_REQUEST,
      window.google.maps.GeocoderStatus.ZERO_RESULTS,
    ].includes(status)
  }

  fromAddress(address, bounds) {
    const geocoder = new window.google.maps.Geocoder()

    return new Promise((resolve, reject) => {
      geocoder.geocode({ address, bounds }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const location = results[0].geometry.location
          resolve({ lat: location.lat(), lng: location.lng() })
        } else {
          reject({ status, results })
        }
      })
    })
  }

  fromCenter(center) {
    const geocoder = new window.google.maps.Geocoder()

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: center }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          resolve(results[0])
        } else {
          reject({ status, results })
        }
      })
    })
  }
}

export default Geocoder
