import throttle from "lodash/throttle"

export default function toggleHelpWidgetVisibility() {
  const footer = document.querySelector(".site-footer")
  const zendeskButton = document.querySelector("#zendesk-widget-button")
  if (!footer || !zendeskButton) return

  const onScroll = throttle(() => {
    const footerPosition = footer.getBoundingClientRect()
    const buttonPosition = zendeskButton.getBoundingClientRect()

    if (footerPosition.top < window.innerHeight && buttonPosition.bottom > footerPosition.top) {
      zendeskButton.style.display = "none"
    } else if (footerPosition.top > window.innerHeight && buttonPosition.bottom < footerPosition.top) {
      zendeskButton.style.display = "block"
    }
  }, 250)

  window.addEventListener("scroll", onScroll, { passive: true })
}
