import { Component } from "react"
import PropTypes from "prop-types"

export default class RetryOptions extends Component {
  state = {
    failedResolutionCount: 0,
    options: null,
    ticket: null,
    ticketStatus: null,
    loading: false,
    displayRetry: false,
  }

  constructor(props) {
    super(props)

    this.doRetry = this.doRetry.bind(this)
  }

  doRetry(e) {
    e.preventDefault()

    this.setState({
      failedResolutionCount: this.state.failedResolutionCount + 1,
    })
    this.props.onRetry()
  }

  render() {
    let counter = ""
    let message = `${this.props.serviceName} doesn't have any available options.`
    let action = this.state.loading ? "Trying…" : "Try again"

    if (this.state.failed) {
      message = `${this.props.serviceName} seems to be down right now.`
    }
    if (this.state.failedResolutionCount > 0) {
      counter = `(${this.state.failedResolutionCount})`
    }

    return (
      <>
        <span>{message}</span>
        <span>
          &nbsp;
          <a href="" onClick={this.doRetry}>
            {action}
            {counter}
          </a>
        </span>
      </>
    )
  }
}

RetryOptions.propTypes = {
  field: PropTypes.object,
  fieldName: PropTypes.string,
  serviceName: PropTypes.string,
  onRetry: PropTypes.func,
}
