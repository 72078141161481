import PropTypes from "prop-types"

/**
 * Capture unhandled link clicks from children and open them in a new window
 * instead of the current one.
 */
const OpenLinksInNewWindow = ({ children }) => (
  <div onClick={handleClick}>{children}</div>
)

function handleClick(e) {
  if (
    e.target.tagName === "A" &&
    !e.target.getAttribute("data-open-in-current-window") &&
    !e.defaultPrevented
  ) {
    e.preventDefault()
    window.open(e.target.href, "_blank")
  }
}

OpenLinksInNewWindow.propTypes = {
  children: PropTypes.node,
}

export default OpenLinksInNewWindow
