import compact from "lodash/compact"
import has from "lodash/has"
import sortBy from "lodash/sortBy"
import toPairs from "lodash/toPairs"

/**
 * This is a much lighter-weight replacement for lunr, specifically for
 * filtering services and applets in our UI. lunr has a lot of complexity to
 * support full text search, which causes unpredictable and suboptimal behavior
 * for the simple typeahead use cases we have.
 *
 *  We want to match not just the first chars in a search result, but also parts
 *  of the indexed string as well. So we split the indexed strings on spaces
 *  and then compare our search query with 4 char chunks.
 *  example: Query (robot) matches (roboto, irobot, blue robot)
 */
export default class SearchIndex {
  constructor() {
    this._index = {}
  }

  add(id, name, alternateTerms = []) {
    addToIndex(this._index, id, id, 100)
    addToIndex(this._index, id, name, 50)
    let nameWords = name.split(" ")

    nameWords.forEach(namePart => {
      if (namePart !== name) {
        addToIndex(this._index, id, namePart, 5)
      }

      if (namePart.length > 4) {
        for (let i = 1; i < namePart.length - 3; i++) {
          addToIndex(this._index, id, namePart.slice(i), 1)
        }
      }
    })

    if (nameWords.length >= 3) {
      for (let x = 0; x <= nameWords.length - 2; x++) {
        for (let y = x + 1; y <= nameWords.length - 1; y++) {
          addToIndex(this._index, id, nameWords[x].concat(nameWords[y]), 10)
        }
      }
    }

    alternateTerms.forEach(term => {
      addToIndex(this._index, id, term, 2)
    })
  }

  search(query) {
    const normalizedQuery = normalize(query)
    let indexNode = this._index

    for (let i = 0; i < normalizedQuery.length; i++) {
      indexNode = indexNode[normalizedQuery[i]]

      if (!indexNode) return []
    }

    const sortedPairs = sortBy(
      toPairs(indexNode.results),
      ([key, value]) => -value
    )

    return sortedPairs.map(([key, value]) => key)
  }

  searchAndSelect(query, records) {
    const resultIds = this.search(query)
    const recordMap = {}

    // First use the map as a set of relevant ids, then fill it with the actual
    // objects.
    resultIds.forEach(id => {
      recordMap[id] = null
    })

    records.forEach(record => {
      if (has(recordMap, record.id)) {
        recordMap[record.id] = record
      }
    })

    // Now we can return the objects in the correct order, with a `compact`
    // just in case an indexed record is missing for some reason.
    return compact(resultIds.map(id => recordMap[id]))
  }
}

/**
 * The index looks like this, assuming a service called "IFTTT" with the id
 * "1" and a `boost` of 1:
 *
 *     {
 *       i: {
 *         results: {
 *           "1": 0.2
 *         },
 *         f: {
 *           results: {
 *             "1": 0.4
 *           },
 *           t: {
 *             results: {
 *               "1": 0.6
 *             },
 *             t: {
 *               results: {
 *                 "1": 0.8
 *               },
 *               t: {
 *                 results: {
 *                   "1": 1
 *                 }
 *               }
 *             }
 *           }
 *         }
 *       }
 *     }
 */
function addToIndex(index, id, term, boost) {
  const normalizedTerm = normalize(term || "")
  if (!normalizedTerm) return

  let indexNode = index

  for (let i = 0; i < normalizedTerm.length; i++) {
    const c = normalizedTerm[i]
    const weight = ((i + 1) / normalizedTerm.length) * boost

    if (!indexNode[c]) indexNode[c] = { results: {} }

    indexNode = indexNode[c]

    indexNode.results[id] = (indexNode.results[id] || 0) + weight
  }
}

function normalize(text) {
  return text.toLowerCase().replace(/\W/g, "")
}
