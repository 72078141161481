import PropTypes from "prop-types"
import classnames from "classnames"

export default function Form({
  children,
  onSubmit,
  disabled,
  formClasses,
  formRef,
}) {
  return (
    <form
      ref={formRef}
      className={classnames("form", formClasses)}
      onSubmit={onSubmit}
    >
      {children}
      {onSubmit && (
        <input type="submit" style={{ display: "none" }} disabled={disabled} />
      )}
    </form>
  )
}

Form.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  formClasses: PropTypes.object,
  formRef: PropTypes.object,
}

Form.defaultProps = {
  disabled: false,
  formClasses: {},
}
