import { Children, Component } from "react"
import { Remarkable } from "remarkable"
import PropTypes from "prop-types"
import parse from "html-react-parser"

export default class SharedMarkdown extends Component {
  render() {
    let Container = this.props.container

    return <Container>{this.content()}</Container>
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextProps.options !== this.props.options) {
      this.md = new Remarkable(nextProps.options)
    }
  }

  content() {
    if (this.props.source) {
      return (
        <span>{parse(this.renderMarkdown(this.props.source))}</span>
      )
    } else {
      return Children.map(this.props.children, child => {
        if (typeof child === "string") {
          return (
            <span>{parse(this.renderMarkdown(child))}</span>
          )
        } else {
          return child
        }
      })
    }
  }

  renderMarkdown(source) {
    if (!this.md) {
      this.md = new Remarkable(this.props.options)
    }

    // Force lazy loading for all rendered <img> tags
    if (this.props.options["html"]) {
      return this.md.render(source).replace(/<img/g, "<img loading='lazy'")
    } else {
      return this.md.render(source)
    }
  }
}

SharedMarkdown.propTypes = {
  container: PropTypes.string,
  options: PropTypes.any,
  source: PropTypes.string,
  children: PropTypes.any,
}

SharedMarkdown.defaultProps = {
  container: "div",
  options: {},
}
