import { Component } from "react"
import PropTypes from "prop-types"
import throttle from "lodash/throttle"
import escape from "lodash/escape"
import pick from "lodash/pick"
import isEmpty from "lodash/isEmpty"
import classNames from "classnames"
import parse from "html-react-parser"

import clearTextIcon from "images/icons/close.svg"
import searchIcon from "images/icons/search.svg"

export const RELEVANT_STYLES = [
  "borderBottomStyle",
  "borderLeftStyle",
  "borderRightStyle",
  "borderTopStyle",
  "borderBottomWidth",
  "borderLeftWidth",
  "borderRightWidth",
  "borderTopWidth",
  "color",
  "opacity",
  "fontFamily",
  "fontSize",
  "fontWeight",
  "letterSpacing",
  "lineHeight",
  "marginBottom",
  "marginLeft",
  "marginRight",
  "marginTop",
  "paddingBottom",
  "paddingLeft",
  "paddingRight",
  "paddingTop",
]

export default class GrowingTextArea extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mirrorStyle: {},
      hide: props.hideAtStart,
    }

    this.handleChange = this.handleChange.bind(this)
    this.updateMirrorStyles = throttle(this.updateMirrorStyles.bind(this), 500)
    this.handleIconClick = this.handleIconClick.bind(this)
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
  }

  componentDidMount() {
    this.updateMirrorStyles()
    window.addEventListener("resize", this.updateMirrorStyles)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateMirrorStyles)
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, _prevState) {
    if (!!prevProps.disabled && !this.props.disabled) {
      this.updateMirrorStyles()
    }
  }

  updateMirrorStyles() {
    if (!this.textareaRef) {
      return
    }

    // HACK: If we're showing the mirror div, we need to temporarily remove the
    // `hidden-text` class so that we can get the real text color. This may
    // result in a flicker of slightly weird-looking text for the user,
    // although in principle it shouldn't. This only happens on window resize
    // and initial render, and only if we're using the `showMirror` prop for an
    // advanced use case like <IngredientTextField>.
    // :<>
    if (this.props.showMirror) {
      this.textareaRef.className = ""
    }

    const mirrorStyle = pick(
      window.getComputedStyle(this.textareaRef),
      RELEVANT_STYLES
    )

    if (this.props.showMirror) {
      this.textareaRef.className = "hidden-text"
    }

    // HACK: iOS has 3px of unremovable horizontal padding on <textarea>
    // elements, so we need to compensate here to make sure the <textarea> and
    // mirror line up.
    const userAgent = window.navigator.userAgent
    if (userAgent.match(/iPad|iPhone/i)) {
      mirrorStyle.paddingLeft = `calc(${mirrorStyle.paddingLeft} + 3px)`
      mirrorStyle.paddingRight = `calc(${mirrorStyle.paddingRight} + 3px)`
    }

    if (this.props.minRows) {
      mirrorStyle.minHeight = `calc(
        (${mirrorStyle.lineHeight} * ${this.props.minRows}) +
        ${mirrorStyle.paddingTop} + ${mirrorStyle.paddingBottom} +
        ${mirrorStyle.borderTopWidth} + ${mirrorStyle.borderBottomWidth}
      )`
    }

    this.setState({ mirrorStyle })
  }

  handleChange(e) {
    this.props.onChange?.(e.target.value)
  }

  handleOnKeyDown(e) {
    this.props.onKeyDown?.(e)
  }

  handleOnBlur(e) {
    e.stopPropagation()
    this.setState({ hide: this.props.hideAtStart })
    e.target.value.length && this.props.onBlur?.(e)
  }

  mirrorHtml() {
    // Add extra newline to compensate for final empty line getting chomped
    const value = escape(`${this.props.value}\n`)

    if (this.props.transformMirrorContent) {
      return this.props.transformMirrorContent(value)
    } else {
      return value
    }
  }

  // Public: focus the <textarea>.
  focus(unhide = false) {
    if (unhide) {
      this.setState({ hide: false })
      this.textareaRef.parentNode.className = classNames("growing-text-area", { hide: false })
    }
    this.textareaRef.focus()
  }

  // Public: value in <textarea>
  valueEntered() {
    return this.textareaRef.value
  }

  // Public: get the current cursor position.
  getCursorPosition() {
    return this.textareaRef.selectionStart
  }

  // Public: focus the <textarea> and set the cursor position.
  setCursorPosition(position) {
    const textarea = this.textareaRef

    if (textarea.setSelectionRange) {
      textarea.focus()
      textarea.setSelectionRange(position, position)
    } else if (textarea.createTextRange) {
      const range = textarea.createTextRange()
      range.collapse(true)
      range.moveEnd("character", position)
      range.moveStart("character", position)
      range.select()
    }
  }

  handleIconClick(event) {
    event.preventDefault()
    this.props.actionIconOnClick(event)
  }

  render() {
    const {
      actionIcon,
      showMirror,
      multiline,
      name,
      value,
      onFocus,
      disabled,
      maxLength,
      required,
    } = this.props

    const domProps = {
      name,
      value,
      onFocus,
      disabled,
      maxLength,
      required,
      className: classNames({
        "hidden-text": showMirror,
        "with-icon": !isEmpty(actionIcon),
      }),
      onChange: this.handleChange,
      onKeyDown: this.handleOnKeyDown,
      onBlur: this.handleOnBlur,
      ref: r => (this.textareaRef = r),
    }

    return (
      <div className={classNames("growing-text-area", { hide: this.state.hide })}>
        {actionIcon && (
          <div
            className={classNames({
              "action-icon": true,
              search: actionIcon !== "clearText",
              "clear-text": actionIcon === "clearText",
            })}
            onClick={this.handleIconClick}
          >
            {parse(actionIcon === "clearText" ? clearTextIcon : searchIcon)}
          </div>
        )}
        {multiline ? (
          <>
            <textarea {...domProps} />
            <div
              className={classNames({
                visible: showMirror,
              })}
              style={this.state.mirrorStyle}
            >
              {parse(this.mirrorHtml())}
            </div>
          </>
        ) : (
          <input type="text" {...domProps} />
        )}
      </div>
    )
  }
}

GrowingTextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  showMirror: PropTypes.bool,
  transformMirrorContent: PropTypes.func,
  minRows: PropTypes.number,
  actionIcon: PropTypes.oneOf([null, "clearText", "search"]),
  actionIconOnClick: PropTypes.func,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  hideAtStart: PropTypes.bool,
}

GrowingTextArea.defaultProps = {
  actionIcon: null,
  multiline: true,
  hideAtStart: false,
}
