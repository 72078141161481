import { cloneElement, Component } from "react"
import PropTypes from "prop-types"

import ClickOutside from "react-click-outside"

export default class IngredientSuggestions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: 0,
      lastService: props.lastSelectedSuggestion || "",
    }

    this.onMouseOver = this.onMouseOver.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.handleComposerSelection = this.handleComposerSelection.bind(this)
  }

  handleLastSelected = e => {
    this.setState({ lastService: e.target.value }, () => {
      this.props.onChange(this.state.lastService)
      this.props.handleLastSelectedSuggestion(this.state.lastService)
    })
  }

  renderSelector = () => {
    return cloneElement(this.props.selector, {
      ref: node => {
        this.selectEl = node
      },
      value: this.state.lastService,
      onChange: this.handleLastSelected,
    })
  }

  componentDidMount() {
    this.props.shouldHideSuggestions(false)
    document.addEventListener("keydown", this.onKeyDown, false)
  }

  componentWillUnmount() {
    this.props.shouldHideSuggestions(true)
    document.removeEventListener("keydown", this.onKeyDown, false)
  }

  onClickOutside = () => {
    this.props.shouldHideSuggestions(true)
  }

  onKeyDown(e) {
    const selected = this.state.selected
    const numSuggestions = this.props.suggestions.length

    if (e.target === this.selectEl) {
      // return early if over select box
      e.preventDefault()
      return
    }

    if (e.key === "ArrowUp" && selected > 0) {
      e.preventDefault()
      this.setState({ selected: selected - 1 })
      return
    }

    if (e.key === "ArrowDown" && selected < numSuggestions - 1) {
      e.preventDefault()
      this.setState({ selected: selected + 1 })
      return
    }

    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault()
      e.stopPropagation()
      this.props.onSelect(this.props.suggestions[selected])
      return
    }

    if (e.key === "Escape") {
      e.preventDefault()
      this.props.onClose()
      return
    }
  }

  onMouseOver(suggestion) {
    const selected = this.props.suggestions.indexOf(suggestion)
    this.setState({ selected })
  }

  suggestion(name, selected, onSelect) {
    const className = selected ? "selected" : ""
    const service = this.props.service

    const style = selected ? { backgroundColor: service.background_color } : {}
    return (
      <li
        key={name}
        className={className}
        style={style}
        onClick={onSelect}
        onMouseOver={() => this.onMouseOver(name)}
      >
        <span>{name}</span>
      </li>
    )
  }

  handleComposerSelection(option) {
    const fullIngredientName =
      this.selectEl.options[this.selectEl.selectedIndex].value + "." + option
    this.props.onSelect(fullIngredientName)
  }

  render() {
    const {
      service,
      trigger,
      isOnColor,
      suggestions,
      platformComposer,
      onSelect,
    } = this.props
    const elements = suggestions.map((opt, index) =>
      this.suggestion(opt, index == this.state.selected, () => {
        platformComposer && !this.props.hideIngredientNamespaces
          ? this.handleComposerSelection(opt)
          : onSelect(opt)
      })
    )

    const iconUrl =
      service.lrg_monochrome_image_url ||
      service.icon_monochrome_url ||
      service.icon_url

    return (
      <ClickOutside onClickOutside={this.onClickOutside}>
        <div
          className={`ingredient-suggestions ${
            isOnColor && "ingredient-suggestions-on-color"
          }`}
          style={{
            backgroundColor: isOnColor ? "#fff" : service.background_color,
          }}
        >
          <div
            className="ingredient-suggestions-left"
            style={{ backgroundColor: service.background_color }}
          >
            <div
              className="logo"
              title={platformComposer ? service.name : trigger.name}
              style={{ backgroundImage: `url('${iconUrl}')` }}
            />
            <div>{platformComposer ? service.name : trigger.name}</div>
          </div>
          <div className="ingredient-suggestions-right">
            {platformComposer && this.renderSelector()}
            <ul
              onMouseDown={e => e.preventDefault() /* avoid stealing focus */}
            >
              {elements}
            </ul>
          </div>
        </div>
      </ClickOutside>
    )
  }
}

IngredientSuggestions.propTypes = {
  suggestions: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  trigger: PropTypes.object.isRequired,
  isOnColor: PropTypes.bool.isRequired,
  platformComposer: PropTypes.bool.isRequired,
  selector: PropTypes.element,
  onChange: PropTypes.func,
  shouldHideSuggestions: PropTypes.func,
  hideIngredientNamespaces: PropTypes.bool,
  lastSelectedSuggestion: PropTypes.string,
  handleLastSelectedSuggestion: PropTypes.func,
}

IngredientSuggestions.defaultProps = {
  platformComposer: false,
}
