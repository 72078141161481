import { Component } from "react"
import PropTypes from "prop-types"
import range from "lodash/range"
import padStart from "lodash/padStart"
import TimeOfDayField from "shared/components/stored_fields/time_of_day_field"
import strftime from "strftime"

import "./date_and_time_field.scss"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "Jun",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export default class DateAndTimeField extends Component {
  constructor(props) {
    super(props)

    const [date, timeOfDay] = props.value.split("T")
    const [_year, month, day] = date.split("-")

    this.state = {
      month: parseInt(month),
      day: parseInt(day),
      year: _year === "0000" ? new Date().getFullYear() : parseInt(_year),
      timeOfDay,
    }

    this.onMonthChange = this.onMonthChange.bind(this)
    this.onDayChange = this.onDayChange.bind(this)
    this.onTimeChange = this.onTimeChange.bind(this)
  }

  componentDidMount() {
    if (this.props.onChange) {
      this.props.onChange(this.selectedDateAndTime())
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.month != prevState.month ||
      this.state.day != prevState.day ||
      this.state.timeOfDay != prevState.timeOfDay
    ) {
      this.props.onChange && this.props.onChange(this.selectedDateAndTime())
    }
  }

  onMonthChange(e) {
    this.setState({ month: e.target.value })
  }

  onDayChange(e) {
    this.setState({ day: e.target.value })
  }

  onTimeChange(timeOfDay) {
    this.setState({ timeOfDay })
  }

  monthOptions() {
    return months.map((month, index) => {
      return (
        <option value={index + 1} key={index + 1}>
          {month}
        </option>
      )
    })
  }

  maxDayForMonth(index) {
    const lastDay = new Date(this.state.year, index, 0).getDate()
    return lastDay === 28 ? 29 : lastDay
  }

  dayOptions() {
    const maxDay = this.maxDayForMonth(this.state.month)
    return range(1, maxDay + 1).map(day => {
      return (
        <option value={day} key={day}>
          {padStart(day, 2, "0")}
        </option>
      )
    })
  }

  selectedDateAndTime() {
    return `${this.state.year}-${padStart(this.state.month, 2, "0")}-${
      this.state.day
    }T${this.state.timeOfDay}`
  }

  render() {
    return (
      <div>
        <select
          styleName="month-options"
          value={this.state.month}
          onChange={this.onMonthChange}
          disabled={this.props.disabled}
        >
          {this.monthOptions()}
        </select>
        <select
          styleName="day-options"
          value={this.state.day}
          onChange={this.onDayChange}
          disabled={this.props.disabled}
        >
          {this.dayOptions()}
        </select>
        &nbsp;
        <TimeOfDayField onChange={this.onTimeChange} value={this.state.timeOfDay} disabled={this.props.disabled} />
        <input type="hidden" name={this.props.fieldName} value={this.selectedDateAndTime()} />
      </div>
    )
  }
}

DateAndTimeField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

DateAndTimeField.defaultProps = {
  value: roundTime(),
}

window.strftime = strftime

function roundTime() {
  const now = new Date()
  const minutes = Math.round(now.getMinutes() / 15) * 15
  now.setMinutes(minutes)

  return strftime(`%Y-%m-%dT%H:%M`, now)
}
